import React, { Fragment } from 'react'
import useMedia from 'use-media'
import Layout from '../layout'
import {
  SEO,
  Overview,
  Benefits,
  Tour,
  Feature,
  More,
  Action,
  Requests,
  Plan,
} from '../components'

import MobileFirstIcon from '../assets/icons/mobile-first.svg'
import SDKsIcon from '../assets/icons/sdks.svg'
import BurgerIcon from '../assets/icons/burger.svg'
import CloudIcon from '../assets/icons/cloud.svg'
import PaintIcon from '../assets/icons/paint.svg'
import TechSupportIcon from '../assets/icons/tech-support.svg'

import OpenChatWidgetOgImage from '../assets/images/open-chat-widget-seo-image.png'

const OpenChatWidget = () => {
  const isWide = useMedia({ minWidth: 870 })
  const isSmall = useMedia({ minWidth: 767 })
  return (
    <Layout>
      <SEO
        title="LiveChat Platform – Open Chat Widget"
        description="A beautiful chat widget for creative designers and demanding developers"
        image={OpenChatWidgetOgImage}
      />
      <div className="u-bg-black-3">
        <Overview
          title="Open Chat Widget"
          subtitle={
            <Fragment>
              A beautiful chat widget for creative <br /> designers and
              demanding developers
            </Fragment>
          }
          image={{ name: 'openChatWidgetOverview', goDown: true }}
          features={[
            {
              title: (
                <Fragment>
                  <code style={{ fontWeight: 'normal' }}>&lt;iframe&gt;</code>{' '}
                  Apps
                </Fragment>
              ),
              description:
                'A dead-simple but powerful way to brighten up a chat widget. Embed apps right in the conversation!',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }extending-ui/extending-chat-widget/chat-widget-moments/`,
            },
            {
              title: 'JavaScript API',
              description:
                'Chat widget is a part of online experience. Fine-tune its behavior, customizing it to your use case.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }extending-ui/extending-chat-widget/javascript-api/`,
            },
            {
              title: 'Rich Messages',
              description:
                'Modern messaging goes way beyond text. Spice up chats with playful, interactive elements.',
              externalLink: `${
                process.env.GATSBY_DOCS_URL
              }extending-ui/extending-chat-widget/rich-messages/`,
            },
          ]}
          button={{
            label: 'See Chat Widget Docs',
            link: `${
              process.env.GATSBY_DOCS_URL
            }extending-ui/extending-chat-widget/`,
          }}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-xl u-Pb-xs">
        <Feature
          title={
            <Fragment>
              One elegant Chat Widget <br /> for a multitude of use cases
            </Fragment>
          }
          description="Embed it on a website, an e-commerce store, web or mobile apps. Provide one, unified, and pleasing point of contact for customers across all interfaces."
          image="beautifulChat"
          imgObjectFit="none"
        />
        <Feature
          inverse
          title={<Fragment>Mobile-first, performing better than ever</Fragment>}
          description="Our Chat Widget works like a charm on all mobile devices. On top of that, it’s lighter, works quicker, and it won’t slow down your website."
          image="mobileFirst"
          imgObjectFit="none"
        />
        <Feature
          title={<Fragment>Fully accessible</Fragment>}
          description="Chat Widget is now compliant with the WCAG 2.1 AA standard. Screen reader support, keyboard navigation, and color contrast adjustments will make your business more inclusive."
          image="accessibleChat"
          imgObjectFit="contain"
          wrapperStyles={{
            height: isSmall ? '700px' : '450px',
            maxHeight: isSmall ? '700px' : '450px',
            overflow: 'visible',
          }}
          imgStyle={{
            width: isSmall ? '150%' : '100%',
            left: isSmall ? '-150px' : '0px',
          }}
        />
        <Feature
          title={
            <>
              Downloaded 1M times
              <br /> every three minutes
            </>
          }
          description="Hundreds of thousands of websites use our widget to provide the best quality of customer care. Our servers deal with millions of chats every week, all of them handled with the same Agent App."
          component={<Requests />}
        />
      </div>
      <div className="u-bg-black2 u-Pt-xl u-Pb-xs">
        <Benefits
          content={[
            {
              icon: MobileFirstIcon,
              label: 'Mobile-first & lightweight',
              description:
                'Almost half of the chats in our Widget are started from a mobile device.',
            },
            {
              icon: PaintIcon,
              label: 'Fresh, snappy and well-designed',
              description:
                'We love when things look and feel good. The devil is in the detail.',
            },
            {
              icon: CloudIcon,
              label: 'Equipped with APIs',
              description:
                'Chat Widget JavaScript APIs let you integrate it in your project.',
            },
            {
              icon: SDKsIcon,
              label: 'Shipped with SDKs',
              description:
                'Get the same SDKs we build the native LiveChat Widget with. No private APIs!',
            },
            {
              icon: BurgerIcon,
              label: 'We always stay hungry',
              description:
                'Communication is changing, so are we! We ship updates every two weeks.',
            },
            {
              icon: TechSupportIcon,
              label: '+18 years in active development',
              description:
                'The very first version of our widget remembers 2002. Do you?',
            },
          ]}
        />
      </div>
      <div className="u-bg-black-3 u-Pt-xl u-Pb-xl xs:u-Pb-2xl">
        <Plan
          title={
            <Fragment>
              Install chat widget that <br /> customers{' '}
              <span className="u-text-mark u-text-nowrap">actually like</span>
            </Fragment>
          }
          subtitle={
            <Fragment>
              A chat widget is the company’s front desk. Make it look &amp; feel
              <br /> the way a company would like to be remembered. Be creative!
            </Fragment>
          }
        />
        <Tour
          inverse
          features={[
            {
              image: 'openChatWidgetTour1',
              title: 'Make chatting effortless',
              description:
                'Standard forms feel like lots work. Make it quick & easy for customers to pick meeting dates. They will thank you with a smile on their faces.',
            },
            {
              image: 'openChatWidgetTour2',
              title: 'Align the look & feel with the brand',
              description:
                'Customer service lies at the heart of every business. Leave customers impressed with the quality of service they get.',
            },
            {
              image: 'openChatWidgetTour3',
              title: 'Build new experiences with apps',
              description:
                'Embed literally anything right into the conversation. Present customers with games, special forms or drawing boards. It’s up to you what’s inside the iframe.',
            },
          ]}
        />
        <Feature
          title={
            <>
              Not enough? Build your own
              <br />
              chat widget from scratch
            </>
          }
          description="We provide a powerful Customer SDK, which allows you to build all kinds of chat widgets – from very simple to sophisticated web apps. We use the same SDK to ship the native Widget!"
          button={{
            link: `${
              process.env.GATSBY_DOCS_URL
            }extending-ui/extending-chat-widget/customer-sdk/`,
            label: 'See Customer SDK Docs',
          }}
          image="customerSdk"
        />
        <More
          label={
            <Fragment>
              See other
              <span className="u-text-mark u-border-red"> Platform </span>
              products
            </Fragment>
          }
          links={[
            {
              title: 'Open Agent App',
              description:
                'Customize the Agent App with widgets and UI elements.',
              link: '/open-agent-app/',
            },
            {
              title: 'Omnichannel APIs',
              description:
                'Tie multiple communication channels with one nifty protocol.',
              link: '/omnichannel/',
            },
            {
              title: 'Reporting APIs',
              description:
                'Build data-driven dashboards and advanced reporting tools.',
              link: '/data-and-reporting/',
            },
          ]}
        />
      </div>
      <div className="u-bg-black2 u-Py-md">
        <Action
          image="mouseAndCard"
          title={
            <>
              Build a chatting experience
              <br /> that matches the brand
            </>
          }
          button={{
            link: process.env.GATSBY_CONSOLE_URL,
            label: isWide ? 'Open Developer Console' : 'Open Dev Console',
          }}
          link={{
            link: `${
              process.env.GATSBY_DOCS_URL
            }extending-ui/extending-chat-widget/`,
            label: 'explore the docs',
          }}
        />
      </div>
    </Layout>
  )
}

export default OpenChatWidget
